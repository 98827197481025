/* Set a default dark background on the root element */
:root {
  background-color: #1a1a1a;
}

body {
  margin: 0;
  padding: 0;
}

.App {
  text-align: left;
  min-height: 100vh; /* Ensure the App div covers the full viewport height */
  padding: 20px; /* Add padding around the entire app */
  box-sizing: border-box; /* Ensure padding is included in the total width/height */
}

/* Light mode (eggshell) */
.light-mode {
  background-color: #f0ead6; /* Eggshell color */
  color: #333333; /* Dark gray for text */
}

/* Dark mode */
.dark-mode {
  background-color: #1a1a1a; /* Dark background */
  color: #ffffff; /* Light text */
}

/* Toggle switch styles */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
  transition: 0.4s; /* Keep this for the sliding effect */
  border: 2px solid currentColor;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 3px;
  bottom: 2px;
  background-color: currentColor;
  transition: 0.4s; /* Keep this for the sliding effect */
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Icon styles */
.icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
}

.sun-icon {
  left: 6px;
}

.moon-icon {
  right: 6px;
}

/* Light mode styles */
.light-mode .switch {
  background-color: #f0ead6;
}

.light-mode .slider {
  border-color: #333333;
}

.light-mode .slider:before {
  background-color: #333333;
}

.light-mode .sun-icon {
  opacity: 1;
}

.light-mode .moon-icon {
  opacity: 0.5;
}

/* Dark mode styles */
.dark-mode .switch {
  background-color: #1a1a1a;
}

.dark-mode .slider {
  border-color: #ffffff;
}

.dark-mode .slider:before {
  background-color: #ffffff;
}

.dark-mode .sun-icon {
  opacity: 0.5;
}

.dark-mode .moon-icon {
  opacity: 1;
}

/* Blog post list styles */
.blog-list {
  list-style-type: none;
  padding: 0;
}

.blog-list li {
  margin-bottom: 10px;
}

.blog-list a {
  color: #0077cc;
  text-decoration: none;
}

.blog-list a:hover {
  color: #004499;
  text-decoration: underline;
}

.dark-mode .blog-list a {
  color: #6bb9f0;
}

.dark-mode .blog-list a:hover {
  color: #3498db;
}

/* Dark mode toggle positioning */
.dark-mode-toggle {
  position: absolute;
  top: 20px;
  right: 20px;
}

/* Back button styles */
.back-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;  /* Set a fixed width */
  height: 40px; /* Set a fixed height */
  border-radius: 50%; /* Make it circular */
  background-color: #0077cc; /* Use your preferred color */
  color: #ffffff;
  text-decoration: none;
  position: absolute;
  top: 20px;
  left: 20px;
}

.back-button:hover {
  background-color: #005fa3; /* Darker shade for hover state */
}

.back-button svg {
  width: 24px;
  height: 24px;
}

/* Adjust for dark mode */
.dark-mode .back-button {
  background-color: #4a90e2; /* Lighter blue for dark mode */
}

.dark-mode .back-button:hover {
  background-color: #357abd; /* Darker shade for hover in dark mode */
}

/* Arrow icon styles */
.arrow-icon {
  width: 24px;
  height: 24px;
}

/* Content wrapper for proper positioning */
.content-wrapper {
  max-width: 800px; /* Limit the maximum width of the content */
  margin: 0 auto; /* Center the content horizontally */
  padding: 20px;
  padding-top: 60px; /* To ensure content doesn't hide behind the toggle */
}

/* Adjust link colors for better contrast in light mode */
.light-mode a {
  color: #0066cc; /* Darker blue for better visibility on eggshell */
}

.light-mode a:hover {
  color: #004080; /* Even darker blue for hover state */
}

/* You may want to adjust other element colors for better contrast in light mode */
.light-mode .back-button {
  background-color: #0066cc;
  color: #ffffff;
}

.light-mode .back-button:hover {
  background-color: #004080;
}


.linkedin-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #0077b5; /* LinkedIn blue */
  color: #ffffff;
  text-decoration: none;
  position: absolute;
  top: 20px;
  left: 20px;
}

.linkedin-button:hover {
  background-color: #005fa3; /* Darker shade for hover state */
}

.linkedin-button svg {
  width: 24px;
  height: 24px;
}

/* Adjust for dark mode if needed */
.dark-mode .linkedin-button {
  background-color: #0077b5; /* You can keep it the same or adjust as needed */
}

.dark-mode .linkedin-button:hover {
  background-color: #005fa3;
}
